import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import { FC, useState } from 'react'
import { PermissionNames, PharmacyFeatureFlags } from '../../../constants'
import { locale } from '../../../locales'
import EditableSection, { EditableSectionProps } from './EditableSection'
import {
  ClientPermission,
  ClientTypes,
} from '../../../types/entities/ClientPermission'
import theme from '../../../styles/theme'
import { useFormik } from 'formik'
import InfoTooltip from '../../../components/Interactions/InfoTooltip'
const translation = locale.translation.SettingsPage.PermissionsList
const commonTranslation = locale.translation.Common

const getSectionTitle = (clientPermission: ClientPermission) => {
  if (clientPermission.clientType === ClientTypes.Pharmacy) {
    return `${clientPermission.name} [${clientPermission.clientId}]`
  }
  return clientPermission.name
}

const permissionsKeysToPermissionNames: { [key: string]: string } = {
  managePermission: PermissionNames.Manage,
  stockPermission: PermissionNames.Stock,
  commercialsPermission: PermissionNames.Commercials,
  monthEndReconciliationPermission: PermissionNames.MonthEndReconciliation,
  bookInHealthscorePermission: PermissionNames.BookInHealthscore,
}

const permissionKeyToFeatureFlags: { [key: string]: string } = {
  commercialsPermission: PharmacyFeatureFlags.EnableAnalytics,
  monthEndReconciliationPermission:
    PharmacyFeatureFlags.EnableMonthEndReconciliation,
  bookInHealthscorePermission: PharmacyFeatureFlags.EnableBookInHealthScore,
}

export interface EditablePermissionsListSectionProps
  extends EditableSectionProps {
  clientPermission: ClientPermission
  showManagePermission: boolean
  disallowManagePermission?: boolean
  onUpdatedPermissions: (clientId: string, permissions: string[]) => void
  odsToFeatureFlagsMap?: { [key: string]: string[] }
}
const EditablePermissionsListSection: FC<
  EditablePermissionsListSectionProps
> = ({
  clientPermission,
  showManagePermission,
  disallowManagePermission,
  onUpdatedPermissions,
  odsToFeatureFlagsMap,
}) => {
  const [editing, setEditing] = useState<boolean>(false)

  const disablePermissionEdit = (permissionKey: string) => {
    return (
      odsToFeatureFlagsMap &&
      !odsToFeatureFlagsMap[clientPermission.clientId]?.includes(
        permissionKeyToFeatureFlags[permissionKey]
      ) &&
      permissionKey !== 'stockPermission'
    )
  }

  const getInitialValues = () => {
    const result: { [key: string]: boolean } = {}
    Object.keys(permissionsKeysToPermissionNames).forEach(
      (key) =>
        (result[key] = clientPermission.permissions.includes(
          permissionsKeysToPermissionNames[key]
        ))
    )
    return result
  }

  const { setValues: setFormikValues, ...formik } = useFormik({
    initialValues: getInitialValues(),
    enableReinitialize: true,
    onSubmit: () => {},
  })

  const handleCancel = () => {
    formik.resetForm()
  }

  const handleUpdate = () => {
    const updatedPermissions: string[] = []
    Object.keys(formik.values).forEach((key) => {
      if (formik.values[key]) {
        updatedPermissions.push(permissionsKeysToPermissionNames[key])
      }
    })
    onUpdatedPermissions(clientPermission.clientId, updatedPermissions)
  }

  const handleSelectAllAndUpdate = () => {
    Object.keys(permissionsKeysToPermissionNames).forEach((key) => {
      if (key === 'managePermission') {
        formik.setFieldValue(key, showManagePermission)
      } else if (!disablePermissionEdit(key)) {
        formik.setFieldValue(key, true)
      }
    })
  }

  const renderPermissionCheckbox = (permissionKey: string) => {
    if (permissionKey === 'managePermission' && !showManagePermission) {
      return null
    } else
      return (
        <Box component={'li'} key={permissionKey}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  data-testid="permission-checkbox"
                  disabled={
                    !editing ||
                    Boolean(
                      permissionKey === 'managePermission' &&
                        disallowManagePermission
                    ) ||
                    disablePermissionEdit(permissionKey)
                  }
                  checked={formik.values[permissionKey]}
                  onChange={(e) =>
                    formik.setFieldValue(permissionKey, e.target.checked)
                  }
                />
              }
              label={
                disablePermissionEdit(permissionKey) && editing ? (
                  <Box display="flex" gap={theme.spacing(1)}>
                    {
                      translation[
                        permissionsKeysToPermissionNames[permissionKey]
                      ]
                    }
                    <InfoTooltip
                      text={commonTranslation.FeatureNotEnabled(
                        clientPermission.name
                      )}
                    />
                  </Box>
                ) : (
                  translation[permissionsKeysToPermissionNames[permissionKey]]
                )
              }
            />
          </FormGroup>
        </Box>
      )
  }

  return (
    <Box
      key={clientPermission.clientId}
      sx={{ padding: theme.spacing(1), minWidth: theme.spacing(58) }}
    >
      <EditableSection
        onCancel={handleCancel}
        onEditingChanged={(e) => {
          setEditing(e)
        }}
        onUpdate={handleUpdate}
        sectionTitle={getSectionTitle(clientPermission)}
        updateDisabled={false}
        sx={{
          justifyContent: 'space-between',
          backgroundColor: editing ? theme.palette.grey[100] : 'white',
          padding: editing ? theme.spacing(1) : 0,
        }}
        additionalButtons={[
          {
            label: translation.SelectAll,
            onClick: handleSelectAllAndUpdate,
          },
        ]}
      >
        <Box component={'ul'}>
          {Object.keys(permissionsKeysToPermissionNames).map((key) =>
            renderPermissionCheckbox(key)
          )}
        </Box>
      </EditableSection>
    </Box>
  )
}

export default EditablePermissionsListSection
