import { FC, useMemo, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import theme from '../../../styles/theme'
import Typography from '@mui/material/Typography'
import { locale } from '../../../locales'
import TextField from '@mui/material/TextField/TextField'

import EditableSection from './EditableSection'
import { useFormik } from 'formik'
import SwitchWithInfo from '../../../components/Forms/SwitchWithInfo'
import { useSuppliersUniquePerId } from '../../../hooks/useSuppliers'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { removeEmptyDictionaryValues } from '../../../utils/Helpers'
import MultiEmailInput, {
  MultiEmailInputHandle,
} from '../../../components/Forms/MultiEmailInput'

const translations =
  locale.translation.SettingsPage.PharmacySettingsTab.PharmacyDetailsContainer
const commonTranslations = locale.translation.Common

const PharmacyDetailsContainer: FC<{
  authId: string
  pharmacyName: string
  odsCode: string
  emails: string[]
  phoneNumber: string
  supplierAccountMaps?: { [key: string]: string }
  clientInternalSuppliers?: { [key: string]: string }
  autoEdit?: boolean
  isBarcodeScanningEnabled: boolean
  isBarcodeScanningMandatory: boolean
  isScanOneEnterQuantityEnabled: boolean
  onUpdatedPharmacyDetails: (
    odsCode: string,
    emails: string[],
    phoneNumber: string,
    isBarcodeScanningMandatory: boolean,
    isScanOneEnterQuantityEnabled: boolean,
    supplierAccountMaps: { [key: string]: string }
  ) => void
}> = (props) => {
  const [emails, setEmails] = useState<string[]>(props.emails ?? [])
  const [isEditingComplianceEmails, setIsEditingComplianceEmails] =
    useState(false)
  const [hasInvalidEmails, setHasInvalidEmails] = useState<boolean>(false)
  const emailsInputSectionWithTagsHandle = useRef<MultiEmailInputHandle>(null)
  const [
    isEditingBarcodeScanningSettings,
    setIsEditingBarcodeScanningSettings,
  ] = useState(false)
  const [isBarcodeScanningMandatory, setIsBarcodeScanningMandatory] =
    useState<boolean>(props.isBarcodeScanningMandatory)
  const [isScanOneEnterQuantityEnabled, setIsScanOneEnterQuantityEnabled] =
    useState<boolean>(props.isScanOneEnterQuantityEnabled)

  const [isEditingSupplierAccountIds, setIsEditingSupplierAccountIds] =
    useState(false)
  const [supplierAccountMaps, setSupplierAccountMaps] = useState<{
    [key: string]: string
  }>(props.supplierAccountMaps ?? {})

  const { suppliersIdDisplayNameDictionary } = useSuppliersUniquePerId()

  const allSuppliersInSystem = useMemo(() => {
    return Object.keys(suppliersIdDisplayNameDictionary)
      .map((k) => {
        return {
          id: k,
          name: suppliersIdDisplayNameDictionary[k],
        }
      })
      .concat(
        props.clientInternalSuppliers
          ? Object.keys(props.clientInternalSuppliers).map((k) => {
              return {
                id: k,
                name: props.clientInternalSuppliers![k],
              }
            })
          : []
      )
  }, [props.clientInternalSuppliers, suppliersIdDisplayNameDictionary])

  const availableSupplers = useMemo(() => {
    return allSuppliersInSystem.filter(
      (s) => !Object.keys(supplierAccountMaps).includes(s.id)
    )
  }, [allSuppliersInSystem, supplierAccountMaps])

  const formik = useFormik({
    initialValues: {
      phoneNumber: props.phoneNumber || '',
    },
    enableReinitialize: true,
    onSubmit: () => {
      props.onUpdatedPharmacyDetails(
        props.odsCode,
        emails,
        formik.values.phoneNumber,
        isBarcodeScanningMandatory,
        isScanOneEnterQuantityEnabled,
        supplierAccountMaps
      )
    },
  })

  const handleAddSupplierAccountId = (supplierId: string) => {
    setSupplierAccountMaps({
      ...supplierAccountMaps,
      [supplierId]: '',
    })
  }

  const handleErrorsChanged = (hasError: boolean) => {
    setHasInvalidEmails(hasError)
  }

  const renderSupplierAccountIdsSection = () => {
    return (
      <EditableSection
        sectionTitle={translations.SupplierAccountIdsTitle}
        onUpdate={() => {
          const updatedSupplierAccountMaps = {
            ...removeEmptyDictionaryValues(supplierAccountMaps),
          }
          props.onUpdatedPharmacyDetails(
            props.odsCode,
            emails,
            formik.values.phoneNumber,
            props.isBarcodeScanningMandatory,
            props.isScanOneEnterQuantityEnabled,
            updatedSupplierAccountMaps
          )

          setSupplierAccountMaps(updatedSupplierAccountMaps)
        }}
        updateDisabled={false}
        onCancel={() => {
          setIsEditingSupplierAccountIds(false)
          setSupplierAccountMaps(props.supplierAccountMaps ?? {})
        }}
        onEditingChanged={setIsEditingSupplierAccountIds}
        titleSx={{ fontWeight: 600 }}
        autoEdit={Boolean(props.autoEdit)}
        isEditing={isEditingSupplierAccountIds}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(1),
            marginTop: theme.spacing(1),
          }}
        >
          {supplierAccountMaps &&
            Object.keys(supplierAccountMaps).map((k) => {
              const supplier = allSuppliersInSystem.find((s) => s.id === k)
              if (supplier) {
                return (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: theme.spacing(1),
                    }}
                  >
                    <TextField
                      label={translations.Supplier}
                      value={supplier.name}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                      fullWidth
                      disabled={!isEditingSupplierAccountIds}
                    />
                    <TextField
                      label={translations.AccountId}
                      value={supplierAccountMaps[k]}
                      onChange={(e) => {
                        setSupplierAccountMaps({
                          ...supplierAccountMaps,
                          [k]: e.target.value,
                        })
                      }}
                      variant="outlined"
                      fullWidth
                      disabled={!isEditingSupplierAccountIds}
                    />
                  </Box>
                )
              }
              return undefined
            })}
          {Object.keys(availableSupplers).length > 0 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: theme.spacing(1),
                justifyContent: 'space-evenly',
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="select-supplier-label">
                  {translations.SelectSupplier}
                </InputLabel>
                <Select
                  labelId="select-supplier-label"
                  value={''}
                  onChange={(e: SelectChangeEvent) =>
                    handleAddSupplierAccountId(e.target.value)
                  }
                  label={translations.SelectSupplier}
                  disabled={!isEditingSupplierAccountIds}
                >
                  {availableSupplers.map((s) => (
                    <MenuItem key={s.id} value={s.id}>
                      {s.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div style={{ width: '100%' }}></div>
            </Box>
          )}
        </Box>
      </EditableSection>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        gap: theme.spacing(2),
        padding: theme.spacing(1),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(1),
        }}
      >
        <Typography
          sx={{
            paddingY: theme.spacing(1),
            margin: 0,
            color: theme.palette.grey[700],
          }}
          variant="button"
        >
          {translations.PharmacyDetails}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: theme.spacing(2),
            marginBottom: theme.spacing(2),
          }}
        >
          <TextField
            label={translations.PharmacyNameLabel}
            value={props.pharmacyName}
            variant="outlined"
            disabled
            fullWidth
          />
          <TextField
            label={translations.OdsCodeLabel}
            value={props.odsCode}
            variant="outlined"
            disabled
            fullWidth
          />
        </Box>
        <EditableSection
          sectionTitle={translations.DailyComplianceReportSectionTitel}
          onUpdate={() => {
            const emailTags =
              emailsInputSectionWithTagsHandle.current?.getTags() ?? []
            props.onUpdatedPharmacyDetails(
              props.odsCode,
              emailTags,
              formik.values.phoneNumber,
              props.isBarcodeScanningMandatory,
              props.isScanOneEnterQuantityEnabled,
              supplierAccountMaps
            )
            setEmails(emailTags)
          }}
          updateDisabled={!isEditingComplianceEmails || hasInvalidEmails}
          onCancel={() => {
            setEmails(props.emails)
            emailsInputSectionWithTagsHandle.current?.clearValue()
            setIsEditingComplianceEmails(false)
            setHasInvalidEmails(false)
            formik.resetForm()
          }}
          onEditingChanged={setIsEditingComplianceEmails}
          titleSx={{ fontWeight: 600 }}
          autoEdit={Boolean(props.autoEdit)}
          isEditing={isEditingComplianceEmails}
        >
          <MultiEmailInput
            ref={emailsInputSectionWithTagsHandle}
            emails={emails}
            isDisabled={!isEditingComplianceEmails}
            onErrorsChanged={handleErrorsChanged}
            label={translations.RecipientsEmailsLabel}
          />
          <TextField
            label={translations.PhoneNumberLabel}
            value={formik.values.phoneNumber}
            variant="outlined"
            name="phoneNumber"
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            inputProps={{ inputMode: 'numeric' }}
            disabled={!isEditingComplianceEmails}
            error={Boolean(
              formik.touched.phoneNumber && formik.errors.phoneNumber
            )}
            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
            sx={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(1) }}
          />
        </EditableSection>
        <EditableSection
          sectionTitle={translations.BarcodeScanningSectionTitle}
          onUpdate={() =>
            props.onUpdatedPharmacyDetails(
              props.odsCode,
              emails,
              formik.values.phoneNumber,
              isBarcodeScanningMandatory,
              isScanOneEnterQuantityEnabled,
              supplierAccountMaps
            )
          }
          updateDisabled={!isEditingBarcodeScanningSettings}
          onCancel={() => {
            setIsEditingBarcodeScanningSettings(false)
            setIsBarcodeScanningMandatory(props.isBarcodeScanningMandatory)
          }}
          onEditingChanged={setIsEditingBarcodeScanningSettings}
          titleSx={{ fontWeight: 600 }}
          autoEdit={Boolean(props.autoEdit)}
          isEditing={isEditingBarcodeScanningSettings}
        >
          <SwitchWithInfo
            checked={isBarcodeScanningMandatory}
            onChange={() => {
              setIsBarcodeScanningMandatory(!isBarcodeScanningMandatory)
            }}
            testId="mandatory-barcode-scanning-toggle"
            label={translations.MandatoryBarcodeScanningToggleLabel}
            infoTooltipText={
              isEditingBarcodeScanningSettings
                ? props.isBarcodeScanningEnabled
                  ? translations.BarcodeScanningInfo
                  : commonTranslations.FeatureNotEnabled(props.pharmacyName)
                : null
            }
            updateDisabled={
              !isEditingBarcodeScanningSettings ||
              !props.isBarcodeScanningEnabled
            }
          />
          <SwitchWithInfo
            checked={isScanOneEnterQuantityEnabled}
            onChange={() => {
              setIsScanOneEnterQuantityEnabled(!isScanOneEnterQuantityEnabled)
            }}
            testId="allow-scan-one-enter-quantity-toggle"
            label={translations.AllowScanOneEnterQuantityToggleLabel}
            infoTooltipText={
              isEditingBarcodeScanningSettings
                ? props.isBarcodeScanningEnabled
                  ? translations.ScanOnEnterQuantityInfo
                  : commonTranslations.FeatureNotEnabled(props.pharmacyName)
                : null
            }
            updateDisabled={
              !isEditingBarcodeScanningSettings ||
              !props.isBarcodeScanningEnabled
            }
          />
        </EditableSection>
        {renderSupplierAccountIdsSection()}
      </Box>
    </Box>
  )
}

export default PharmacyDetailsContainer
