import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { usePageTitle } from '../../hooks/usePageTitle'
import { locale } from '../../locales'
import theme from '../../styles/theme'
import UserManagement from './UserManagement'
import GroupsManagement from './GroupsManagement'
import PharmacySettings from './PharmacySettings'
import CompanySettings from './CompanySettings'
import { useRecoilValue } from 'recoil'
import { selectedClientState } from '../../state/SelectedPharmacyState'
import { ServiceContext } from '../../providers/ServicesProvider'
import { ClientTypes } from '../../types/entities/ClientPermission'
import { Client } from './entities/Client'
import { PlatformApiPaths } from '../../PlatformApiPaths'
import { useGlobalIsLoading } from '../../hooks/useIsLoading'
import { GetErrorMessage } from '../../utils/ErrorHandling'
import Alert from '@mui/material/Alert/Alert'

const translations = locale.translation.SettingsPage
const SettingsPage: FC = () => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  let shouldBustUserDetailsCache = useRef<boolean>(false)

  usePageTitle(locale.translation.Navigation.Links.Settings)

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedIndex(newValue)
  }
  const selectedClient = useRecoilValue(selectedClientState)
  const { platformHttpService } = useContext(ServiceContext)
  const [companyDetails, setCompanyDetails] = useState<Client | null>(null)

  const [error, setError] = useState<string | null>(null)
  const canAccess =
    selectedClient?.clientType === ClientTypes.Company &&
    selectedClient?.permissions?.includes('Manage')

  const { setIsLoading } = useGlobalIsLoading()

  const fetchCompanyDetails = useCallback(async () => {
    if (canAccess && selectedClient) {
      setIsLoading(true)
      setError(null)
      const response = await platformHttpService.getAsync<Client>(
        PlatformApiPaths.GetAllCompanyClients(selectedClient),
        'ClientsBaseUri'
      )
      setIsLoading(false)
      if (!response?.hasErrors) {
        setCompanyDetails(response.data)
      } else {
        setError(GetErrorMessage(response.statusCode))
      }
    }
  }, [canAccess, selectedClient, platformHttpService, setIsLoading])

  useEffect(() => {
    fetchCompanyDetails()
  }, [canAccess, fetchCompanyDetails, selectedClient])

  return (
    <Box
      sx={{
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
    >
      {canAccess && error && (
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      )}
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedIndex} onChange={handleTabChange}>
          <Tab
            label={translations.CompanySettingsTab.Title}
            data-testid="company-settings-tab"
          />
          <Tab
            label={translations.GroupsManagementTab.Title}
            data-testid="groups-management-tab"
          />
          <Tab
            label={translations.PharmacySettingsTab.Title}
            data-testid="pharmacy-settings-tab"
          />
          <Tab
            label={translations.UserManagementTab.Title}
            data-testid="users-tab"
          />
        </Tabs>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          paddingY: 0,
        }}
      >
        {selectedIndex === 0 && companyDetails && (
          <CompanySettings
            clientDetails={companyDetails}
            fetchCompanyDetails={fetchCompanyDetails}
          />
        )}
        {selectedIndex === 1 && companyDetails && (
          <GroupsManagement
            shouldBustLoadUserCacheRef={shouldBustUserDetailsCache}
            clientDetails={companyDetails}
            fetchCompanyDetails={fetchCompanyDetails}
          />
        )}
        {selectedIndex === 2 && companyDetails && (
          <PharmacySettings
            clientDetails={companyDetails}
            fetchCompanyDetails={fetchCompanyDetails}
          />
        )}
        {selectedIndex === 3 && companyDetails && (
          <UserManagement
            shouldBustLoadUserCacheRef={shouldBustUserDetailsCache}
            clientDetails={companyDetails}
            fetchCompanyDetails={fetchCompanyDetails}
          />
        )}
      </Box>
    </Box>
  )
}

export default SettingsPage
