import Box from '@mui/material/Box'
import { FC, useContext, useRef, useState } from 'react'
import theme from '../../styles/theme'
import { ServiceContext } from '../../providers/ServicesProvider'
import { useRecoilValue } from 'recoil'
import { selectedClientState } from '../../state/SelectedPharmacyState'
import { ClientTypes } from '../../types/entities/ClientPermission'
import { locale } from '../../locales'
import { Client, ClientCompanyWithDetails } from './entities/Client'
import { PlatformApiPaths } from '../../PlatformApiPaths'
import { GetErrorMessage } from '../../utils/ErrorHandling'
import { useGlobalIsLoading } from '../../hooks/useIsLoading'
import { InputSectionWithTagsHandle } from '../../components/Forms/InputSectionWithTags'
import EditableSection from './components/EditableSection'
import Alert from '@mui/material/Alert'
import MultiEmailInput from '../../components/Forms/MultiEmailInput'

const translations = locale.translation.SettingsPage.CompanySettingsTab

const CompanySettings: FC<{
  clientDetails: Client
  fetchCompanyDetails: () => void
}> = ({ clientDetails, fetchCompanyDetails }) => {
  const { platformHttpService } = useContext(ServiceContext)
  const selectedClient = useRecoilValue(selectedClientState)
  const [companyDetails, setCompanyDetails] = useState<Client | null>(
    clientDetails
  )
  const { setIsLoading } = useGlobalIsLoading()
  const [error, setError] = useState<string | null>(null)
  const canAccess =
    selectedClient?.clientType === ClientTypes.Company &&
    selectedClient?.permissions?.includes('Manage')
  const [editing, setEditing] = useState<boolean>(false)
  const [hasInvalidEmails, setHasInvalidEmails] = useState<boolean>(false)
  const emailsInputSectionWithTagsHandle =
    useRef<InputSectionWithTagsHandle>(null)
  const [originalEmails] = useState<string[]>(
    companyDetails?.complianceEmailRecipients ?? []
  )

  const onUpdatedCompanyDetails = async (emails: string[]) => {
    if (selectedClient && companyDetails) {
      setError(null)
      setIsLoading(true)
      const response =
        await platformHttpService.putAsync<ClientCompanyWithDetails>(
          PlatformApiPaths.GetCompanyWithDetails(selectedClient),
          {
            complianceEmailRecipients: emails,
          },
          'ClientsBaseUri'
        )
      setIsLoading(false)
      if (!response?.hasErrors) {
        fetchCompanyDetails()
      } else {
        setError(GetErrorMessage(response.statusCode))
      }
    }
  }

  const handleErrorsChanged = (hasError: boolean) => {
    setHasInvalidEmails(hasError)
  }

  return (
    <>
      {canAccess && error && (
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      )}
      {canAccess && selectedClient && companyDetails && (
        <Box
          sx={{
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
            gap: theme.spacing(2),
            padding: theme.spacing(1),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(1),
            }}
          >
            <EditableSection
              onCancel={() => {
                emailsInputSectionWithTagsHandle.current?.clearValue()
                setEditing(false)
                companyDetails.complianceEmailRecipients = originalEmails
              }}
              onUpdate={() => {
                const emails =
                  emailsInputSectionWithTagsHandle.current?.getTags() ?? []
                onUpdatedCompanyDetails(emails)
                setCompanyDetails({
                  ...companyDetails,
                  complianceEmailRecipients: emails,
                })
              }}
              sectionTitle={translations.WeeklyComplianceReportSectionTitel}
              updateDisabled={!editing || hasInvalidEmails}
              onEditingChanged={(editing) => setEditing(editing)}
              titleSx={{ fontWeight: 600 }}
              isEditing={editing}
            >
              <MultiEmailInput
                ref={emailsInputSectionWithTagsHandle}
                emails={companyDetails.complianceEmailRecipients}
                isDisabled={!editing}
                onErrorsChanged={handleErrorsChanged}
                label={translations.RecipientsEmailsLabel}
              />
            </EditableSection>
          </Box>
        </Box>
      )}
    </>
  )
}

export default CompanySettings
