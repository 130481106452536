import Box from '@mui/material/Box'
import { FC, useContext, useRef, useState } from 'react'
import theme from '../../styles/theme'
import { ServiceContext } from '../../providers/ServicesProvider'
import { useRecoilValue } from 'recoil'
import { selectedClientState } from '../../state/SelectedPharmacyState'
import { ClientTypes } from '../../types/entities/ClientPermission'
import Alert from '@mui/material/Alert'
import { Client, ClientPharmacy } from './entities/Client'
import { PlatformApiPaths } from '../../PlatformApiPaths'
import Typography from '@mui/material/Typography'
import VirtualizedSelectableList, {
  VirtualizedSelectableListHandle,
} from '../../components/Data/VirtualizedSelectableList'
import { GetErrorMessage } from '../../utils/ErrorHandling'
import AutoSizingBox from '../../components/Util/AutoSizingBox'
import { useGlobalIsLoading } from '../../hooks/useIsLoading'
import PharmacyDetailsContainer from './components/PharmacyDetailsContainer'
import { PharmacyFeatureFlags } from '../../constants'

const PharmacySettings: FC<{
  clientDetails: Client
  fetchCompanyDetails: () => void
}> = ({ clientDetails, fetchCompanyDetails }) => {
  const { platformHttpService } = useContext(ServiceContext)
  const selectedClient = useRecoilValue(selectedClientState)
  const { setIsLoading } = useGlobalIsLoading()
  const [error, setError] = useState<string | null>(null)
  const [client, setClient] = useState<Client | null>(clientDetails)
  const [selectedPharmacy, setselectedPharmacy] =
    useState<ClientPharmacy | null>(null)
  const canAccess =
    selectedClient?.clientType === ClientTypes.Company &&
    selectedClient?.permissions?.includes('Manage')
  const listHandleRef = useRef<VirtualizedSelectableListHandle>(null)

  const handleselectedPharmacyIndexChanged = async (index: number) => {
    if (index >= 0 && client?.pharmacies) {
      const pharmacy = client.pharmacies[index]
      setselectedPharmacy(pharmacy)
    }
  }

  const handlePharmacyDetailsUpdated = async (
    odsCode: string,
    emails: string[],
    phoneNumber: string,
    isBarcodeScanningMandatory: boolean,
    isScanOneEnterQuantityEnabled: boolean,
    supplierAccountMaps: { [key: string]: string }
  ) => {
    if (selectedClient && selectedPharmacy) {
      setError(null)
      setIsLoading(true)
      const response = await platformHttpService.putAsync<ClientPharmacy>(
        PlatformApiPaths.GetPharmacyWithDetails(selectedClient),
        {
          odsCode: odsCode,
          complianceEmailRecipients: emails,
          phoneNumber: phoneNumber,
          isBarcodeScanningMandatory,
          isScanOneEnterQuantityEnabled,
          supplierAccountMaps
        },
        'ClientsBaseUri'
      )
      setIsLoading(false)
      if (!response || response?.hasErrors) {
        setError(GetErrorMessage(response.statusCode))
      } else {
        const updatedPharmacy = {
          ...selectedPharmacy,
          complianceEmailRecipients: emails,
          phoneNumber: phoneNumber,
          setFeatureFlags: [
            ...(selectedPharmacy.setFeatureFlags ?? []).filter(
              (flag) => flag !== PharmacyFeatureFlags.EnableMandatoryScanning
            ),
            ...(isBarcodeScanningMandatory
              ? [PharmacyFeatureFlags.EnableMandatoryScanning]
              : []),
            ...(isScanOneEnterQuantityEnabled
              ? [PharmacyFeatureFlags.EnableScanOneEnterQuantity]
              : []),
          ],
          supplierAccountMaps
        }
        setselectedPharmacy(updatedPharmacy)
        fetchCompanyDetails()

        if (client) {
          const updatedPharmacies = client.pharmacies.map((pharmacy) =>
            pharmacy.odsCode === odsCode ? updatedPharmacy : pharmacy
          )
          setClient({ ...client, pharmacies: updatedPharmacies })
        }
      }
    }
  }

  return (
    <>
      {canAccess && error && (
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      )}
      {canAccess && client && client.pharmacyGroups && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            height: '100%',
          }}
        >
          <Box
            data-testid="groups-list-pane"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              borderRight: `1px solid ${theme.palette.grey[300]}`,
            }}
          >
            <VirtualizedSelectableList
              ref={listHandleRef}
              rowHeight={parseInt(theme.spacing(9))}
              rowCount={client.pharmacies.length}
              height={'100%'}
              width={'400px'}
              items={client.pharmacies}
              buttonContent={(p) => {
                const pharmacy = p as ClientPharmacy
                return (
                  <>
                    <Typography variant="body1">
                      {pharmacy.pharmacyName}
                    </Typography>
                    <Typography variant="body2">{`${pharmacy.odsCode}`}</Typography>
                  </>
                )
              }}
              onSelectedItemIndexChanged={handleselectedPharmacyIndexChanged}
              selectedIndex={
                client?.pharmacies
                  ? client.pharmacies.findIndex(
                      (u) => u.odsCode === selectedPharmacy?.odsCode
                    )
                  : undefined
              }
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              paddingLeft: theme.spacing(1),
            }}
            data-testid="groups-details-pane"
          >
            <AutoSizingBox>
              {selectedPharmacy?.pharmacyName && selectedPharmacy?.odsCode && (
                <PharmacyDetailsContainer
                  key={selectedPharmacy.odsCode}
                  odsCode={selectedPharmacy.odsCode}
                  pharmacyName={selectedPharmacy.pharmacyName}
                  onUpdatedPharmacyDetails={handlePharmacyDetailsUpdated}
                  authId={selectedClient.clientId}
                  emails={selectedPharmacy.complianceEmailRecipients!}
                  supplierAccountMaps={selectedPharmacy.supplierAccountMaps}
                  clientInternalSuppliers={clientDetails.internalSuppliers}
                  phoneNumber={selectedPharmacy.phoneNumber || ''}
                  isBarcodeScanningEnabled={
                    selectedPharmacy.setFeatureFlags?.includes(
                      PharmacyFeatureFlags.EnableBarcodeScanning
                    ) ?? false
                  }
                  isBarcodeScanningMandatory={
                    selectedPharmacy.setFeatureFlags?.includes(
                      PharmacyFeatureFlags.EnableMandatoryScanning
                    ) || false
                  }
                  isScanOneEnterQuantityEnabled={
                    selectedPharmacy.setFeatureFlags?.includes(
                      PharmacyFeatureFlags.EnableScanOneEnterQuantity
                    ) || false
                  }
                />
              )}
            </AutoSizingBox>
          </Box>
        </Box>
      )}
    </>
  )
}
export default PharmacySettings
