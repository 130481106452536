import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { locale } from '../../locales'
import { ServiceContext } from '../../providers/ServicesProvider'
import theme from '../../styles/theme'
import {
  CommercialsResponse,
  CommercialsItem,
  CommercialsGroupedItem,
} from './entities/Commercials'
import { sum } from 'lodash'
import PercentChange from './components/PercentChange'
import { PlatformApiPaths } from '../../PlatformApiPaths'
import { GetErrorMessage } from '../../utils/ErrorHandling'
import CommercialsProductDetailsTabs from './components/CommercialsProductDetailsTabs'
import {
  getDateOnlyIsoString,
  getDateOnlyShortMonthString,
  packSizeConverter,
  toCurrencyString,
} from '../../utils/Helpers'
import SuppliersChart from './components/SuppliersChart'
import ButtonPopOver from '../../components/Interactions/ButtonPopOver'
import PieChartIcon from '@mui/icons-material/PieChart'
import InfoTooltip from '../../components/Interactions/InfoTooltip'
import VirtuosoMuiTable, {
  VirtuosoColumn,
} from '../../components/Data/VirtuosoMuiTable'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { SxProps, Theme } from '@mui/material/styles'
import ButtonBase from '@mui/material/ButtonBase'
import {
  calculateAverageRebateMargin,
  calculateVmpGroupFigures,
  roundToTwoDecimalPlaces,
} from './helpers'
import SwitchWithInfo from '../../components/Forms/SwitchWithInfo'
import { DatePresetsLocalStorageKeys, RecoilPageIds } from '../../constants'
import {
  PageAction,
  PageReducer,
  PageState,
  ReducerHelpers,
  createRecoilPageState,
  usePageState,
} from '../PageState'
import FilterBar from '../../components/Interactions/FilterBar'
import { arrayTextFiltering, textFiltering } from '../../utils/FilteringUtls'
import { sortArrayByPropertyKey } from '../../utils/SortingUtils'
import { useGlobalIsLoading } from '../../hooks/useIsLoading'
import Button from '@mui/material/Button/Button'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import ConfirmDialog from '../../components/Interactions/ConfirmDialog'
import ProductFilterBar, {
  ProductFilterBarState,
  InitialProductFilterBarState,
  productFilterBarFilter,
} from '../../components/Interactions/ProductFilterBar'

const translation = locale.translation.AnalyticsPage.TabCommercials

// Page-specific action types
type SetRollConcessionsOverAction = {
  type: 'setRollConcessionsOver'
  payload: boolean
}

type SetUseTradePriceAction = {
  type: 'setUseTradePrice'
  payload: boolean
}

type SetGroupByGenericAction = {
  type: 'setGroupByGeneric'
  payload: boolean
}

type SetUseSupplierNetPricesAction = {
  type: 'setUseSuppliersNetPrices'
  payload: boolean
}

type SetCommercialsResponseAction = {
  type: 'setCommercialsResponse'
  payload: CommercialsResponse
}

type ToggleProductExpandedAction = {
  type: 'toggleProductExpanded'
  payload: { productId: string; packSize: number }
}

type SetProductsFilterBarStateAction = {
  type: 'setProductsFilterBarState'
  payload: ProductFilterBarState
}

type CommercialsPageAction =
  | PageAction<CommercialsItem>
  | SetRollConcessionsOverAction
  | SetUseTradePriceAction
  | SetGroupByGenericAction
  | SetUseSupplierNetPricesAction
  | SetCommercialsResponseAction
  | ToggleProductExpandedAction
  | SetProductsFilterBarStateAction

// Page-specific state
interface CommercialsPageState extends PageState<CommercialsItem> {
  productFilterBarState: ProductFilterBarState
  rollConcessionsOver: boolean
  useTradePrice: boolean
  groupByGeneric: boolean
  useSuppliersNetPrices: boolean
  expandedVmpGroups: { productId: string; packSize: number }[]
  commercialsResponse: CommercialsResponse | null
}

// Page specific reducer
class CommercialsPageReducer extends PageReducer<CommercialsItem> {
  reducer(
    state: CommercialsPageState,
    action: CommercialsPageAction
  ): CommercialsPageState {
    const updateFilteredItems = () => {
      state = {
        ...state,
        filteredItems: processItemGroupingSortingAndFiltering(state),
      }
    }

    state = {
      ...(super.reducer(
        state,
        action as PageAction<CommercialsItem>
      ) as CommercialsPageState),
    }

    switch (action.type) {
      case 'setSearchField':
        state = { ...state, searchText: action.payload.text }
        updateFilteredItems()
        break

      case 'setRollConcessionsOver':
        state = {
          ...(ReducerHelpers.resetPaging(
            state as PageState<CommercialsItem>
          ) as CommercialsPageState),
          rollConcessionsOver: action.payload,
        }
        break
      case 'setUseTradePrice':
        state = {
          ...(ReducerHelpers.resetPaging(
            state as PageState<CommercialsItem>
          ) as CommercialsPageState),
          useTradePrice: action.payload,
        }
        break
      case 'setGroupByGeneric':
        state = {
          ...(ReducerHelpers.resetPaging(
            state as PageState<CommercialsItem>
          ) as CommercialsPageState),
          groupByGeneric: action.payload,
        }
        break
      case 'setUseSuppliersNetPrices':
        state = {
          ...(ReducerHelpers.resetPaging(
            state as PageState<CommercialsItem>
          ) as CommercialsPageState),
          useSuppliersNetPrices: action.payload,
        }
        break
      case 'setSorting':
        state = {
          ...state,
          sorting: action.payload.sorting,
        }
        updateFilteredItems()
        break
      case 'setProductsFilterBarState':
        state = {
          ...state,
          productFilterBarState: action.payload,
        }
        updateFilteredItems()
        break
      case 'setCommercialsResponse':
        state = {
          ...state,
          commercialsResponse: action.payload,
        }
        updateFilteredItems()
        break
      case 'toggleProductExpanded':
        const isExpanded = state.expandedVmpGroups.find(
          (i) =>
            i.packSize === action.payload.packSize &&
            i.productId === action.payload.productId
        )
        if (!isExpanded) {
          state = {
            ...state,
            expandedVmpGroups: [...state.expandedVmpGroups, action.payload],
          }
        } else {
          state = {
            ...state,
            expandedVmpGroups: state.expandedVmpGroups.filter(
              (i) =>
                i.packSize !== action.payload.packSize ||
                i.productId !== action.payload.productId
            ),
          }
        }
        updateFilteredItems()
        break
    }

    return state
  }
}

// Create static objects for the recoil state and reducer
const { recoilPageState, recoilTableState } =
  createRecoilPageState<CommercialsItem>(RecoilPageIds.TabCommercials)
const pageReducer = new CommercialsPageReducer()

const VmpRowCss: SxProps<Theme> = {
  fontWeight: 600,
  backgroundColor: theme.palette.grey[100],
}

const columnDefinitions: Array<VirtuosoColumn<CommercialsItem>> = [
  {
    dataKey: 'vmpName',
    label: translation.TableColumnTitles[0],
    widthCss: '30%',
    sortable: true,
  },
  {
    dataKey: 'packSize',
    label: translation.TableColumnTitles[1],
    sortable: false,
    valueTransformer: (row) => packSizeConverter(row.packSize),
  },
  {
    dataKey: 'averagePrice',
    label: translation.TableColumnTitles[2],
    sortable: true,
    currency: true,
    infoTooltip: translation.InfoAverageCostPrice,
  },
  {
    dataKey: 'priceMovement',
    widthCss: '14ch',
    label: translation.TableColumnTitles[3],
    customCellNode: (cellValue, rowData) => (
      <PercentChange
        positiveGreen={false}
        showArrow={true}
        value={roundToTwoDecimalPlaces(cellValue as number)}
      ></PercentChange>
    ),
    sortable: true,
    infoTooltip: translation.InfoPriceMovement,
  },
  {
    dataKey: 'averageRebate',
    label: translation.TableColumnTitles[4],
    sortable: true,
    currency: true,
    widthCss: '18ch',
    customCellNode: (cellValue, rowData) => {
      const text = toCurrencyString(cellValue as number) || '-'
      return (
        <Box>
          <Box
            component="span"
            title={text}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color:
                rowData && !rowData.hasRebateForEveryDay && cellValue != null
                  ? theme.palette.warning.main
                  : undefined,
            }}
          >
            {text}
            {cellValue != null && !rowData.hasRebateForEveryDay && (
              <InfoTooltip
                mode="info"
                useColorWhenNotActive={true}
                colorOverride={theme.palette.warning.main}
                text={translation.RebateNotForEveryDay}
                sx={{
                  marginLeft: theme.spacing(0.5),
                }}
                iconSx={{
                  fontSize: '1.2em',
                }}
              />
            )}
          </Box>
        </Box>
      )
    },
    infoTooltip: translation.InfoAverageRebate,
  },
  {
    dataKey: 'rebateMargin',
    widthCss: '20ch',
    label: translation.TableColumnTitles[5],
    sortable: true,
    customCellNode: (cellValue, rowData) => (
      <PercentChange
        positiveGreen={true}
        showArrow={false}
        value={roundToTwoDecimalPlaces(cellValue as number)}
      ></PercentChange>
    ),
    infoTooltip: translation.InfoRebateMargin,
  },
  {
    dataKey: 'cheapestSupplier',
    label: translation.TableColumnTitles[6],
    sortable: false,
  },
  {
    dataKey: 'totalQuantity',
    label: translation.TableColumnTitles[7],
    sortable: true,
  },
  {
    dataKey: 'totalSpent',
    label: translation.TableColumnTitles[8],
    sortable: true,
    currency: true,
  },
]

const processItemGroupingSortingAndFiltering = (
  state: CommercialsPageState
) => {
  if (state.commercialsResponse) {
    if (!state.groupByGeneric && state.commercialsResponse.items) {
      let items = state.commercialsResponse.items.filter((i) => {
        return textFiltering(state.searchText, i.vmpName)
      })
      items = productFilterBarFilter(
        state.productFilterBarState,
        items
      ) as CommercialsItem[]
      if (state.sorting) {
        sortArrayByPropertyKey(items, {
          sortingType: state.sorting.sortingType,
          sortingPropertyKey: state.sorting.dataKey,
        })
      }
      return items
    } else {
      // Text filtering
      let groupedItems = (state.commercialsResponse.groupedItems ?? []).filter(
        (i) => {
          const productNames = [...i.amps!.flatMap((a) => a.vmpName), i.vmpName]
          return arrayTextFiltering(state.searchText, productNames)
        }
      )

      // Pass one filter amps and apply calculations to group
      for (let i = 0; i < groupedItems.length; i++) {
        const grouped = { ...groupedItems[i] }
        // Apply filters
        let filteredAmps = productFilterBarFilter(state.productFilterBarState, [
          ...grouped.amps!,
        ]) as CommercialsItem[]

        if (filteredAmps.length > 0) {
          groupedItems[i] = {
            ...calculateVmpGroupFigures({ ...grouped, amps: filteredAmps }),
            amps: filteredAmps,
          }
        }
      }

      // Sort
      if (state.sorting) {
        sortArrayByPropertyKey(
          groupedItems,
          {
            sortingType: state.sorting.sortingType,
            sortingPropertyKey: state.sorting.dataKey,
          },
          {
            noOfAmps: (value, rowData: unknown) => {
              return (rowData as CommercialsGroupedItem).amps?.length ?? 0
            },
          }
        )
      }

      let items: CommercialsItem[] = []
      // Pass 2 expand and remove empty groups
      for (let grouped of groupedItems) {
        // Calculate if expanded
        const isExpanded = Boolean(
          state.expandedVmpGroups.find(
            (i) =>
              i.packSize === grouped.packSize &&
              i.productId === grouped.productId
          )
        )

        if ((grouped.amps?.length ?? 0) > 0) {
          // Fill in VMP values
          items.push({
            ...grouped,
            isVmp: true,
          })

          // If expanded, show AMPs
          if (isExpanded) {
            for (let item of grouped.amps!) {
              items.push({ ...item, isVmp: false })
            }
          }
        }
      }
      return items
    }
  }

  return []
}

const TabCommercials: FC = () => {
  const serviceContext = useContext(ServiceContext)
  const httpService = serviceContext.platformHttpService
  const { setIsLoading } = useGlobalIsLoading()
  const [isDownloading, setIsDownloading] = useState<boolean>(false)
  const [exportModalOpen, setExportModalOpen] = useState<boolean>(false)
  const [exportErrors, setExporErrors] = useState<string | null>(null)

  // Handle page state for this page
  const {
    state: genericPageState,
    dispatch: genericDispatch,
    virtuosoTableState,
    shouldRunLoadingEffect,
    odsToPharmacyNameMappings,
    virtuosoTableHelpers,
  } = usePageState<CommercialsItem>(
    pageReducer,
    recoilPageState,
    recoilTableState,
    (item) => null,
    {
      dataKey: 'totalSpent',
      sortingType: 'DESC',
    },
    DatePresetsLocalStorageKeys.COMMERCIALS_DATE_RANGE_PRESET_KEY,
    {
      productFilterBarState: InitialProductFilterBarState,
      rollConcessionsOver: false,
      useTradePrice: true,
      groupByGeneric: false,
      useSuppliersNetPrices: false,
      supplierDistribution: null,
      expandedVmpGroups: [],
    }
  )

  // Type conversion to page specific state
  const state = genericPageState as CommercialsPageState
  const dispatch = genericDispatch as React.Dispatch<CommercialsPageAction>

  const [selectedDetails, setSelectedDetails] =
    useState<CommercialsItem | null>(null)

  const handleExpandClick = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      rowData: CommercialsItem
    ) => {
      e.stopPropagation()
      e.preventDefault()
      if (rowData.isVmp) {
        dispatch({
          type: 'toggleProductExpanded',
          payload: { productId: rowData.productId, packSize: rowData.packSize },
        })
      }
    },
    [dispatch]
  )

  const getColumnDefinitions = useMemo(() => {
    let result = columnDefinitions
    result[3].sortable = !state.groupByGeneric
    if (!state.groupByGeneric) {
      return result
    } else {
      return [
        {
          dataKey: 'expandIcon',
          label: '',
          widthCss: '64px',
          calculatedCellSx: () => {
            return {
              padding: 0,
            }
          },
          customCellNode: (cellValue: unknown, rowData: CommercialsItem) => {
            if (rowData.isVmp) {
              const isExpanded = state.expandedVmpGroups.find(
                (i) =>
                  i.packSize === rowData.packSize &&
                  i.productId === rowData.productId
              )
              return (
                <ButtonBase
                  sx={{ display: 'block', width: '100%', height: '100%' }}
                  onClick={(e) => {
                    handleExpandClick(e, rowData)
                  }}
                >
                  {isExpanded && <ExpandLessIcon />}
                  {!isExpanded && <ExpandMoreIcon />}
                </ButtonBase>
              )
            }
          },
        },
        {
          dataKey: 'noOfAmps',
          label: translation.TableColumnTitles[9],
          valueTransformer: (rowData) =>
            rowData.isVmp
              ? (rowData as CommercialsGroupedItem).amps?.length
              : '',
          sortable: true,
        },
        ...result,
      ] as VirtuosoColumn<CommercialsItem>[]
    }
  }, [handleExpandClick, state.expandedVmpGroups, state.groupByGeneric])

  useEffect(() => {
    const getData = async () => {
      return await httpService.postAsync<CommercialsResponse>(
        PlatformApiPaths.GetAllProductCommercials(state.selectedClient!),
        {
          dateFrom: getDateOnlyIsoString(state.dates[0]),
          dateTo: getDateOnlyIsoString(state.dates[1]),
          supplierIds: state.selectedSupplierIds,
          rollOverConcession: state.rollConcessionsOver,
          groupByVmp: state.groupByGeneric,
          preferIndicativePriceForBranded: state.useTradePrice,
          useSupplierNetPrices: state.useSuppliersNetPrices,
        },
        'StockBaseUrl'
      )
    }
    if (shouldRunLoadingEffect) {
      setIsLoading(true)
      dispatch({ type: 'setError', payload: null })
      getData().then((response) => {
        setIsLoading(false)
        if (response?.data && !response.hasErrors) {
          dispatch({
            type: 'setCommercialsResponse',
            payload: response.data,
          })
        } else {
          dispatch({
            type: 'setError',
            payload: GetErrorMessage(response?.statusCode),
          })
        }
      })
    }
  }, [
    dispatch,
    httpService,
    setIsLoading,
    shouldRunLoadingEffect,
    state.dates,
    state.groupByGeneric,
    state.rollConcessionsOver,
    state.selectedClient,
    state.selectedSupplierIds,
    state.useSuppliersNetPrices,
    state.useTradePrice,
    state.refreshRandom,
  ])

  const handleConfirmFileExport = async () => {
    const getCommercialsExport = async () => {
      const response = await httpService.postAsync<Blob>(
        PlatformApiPaths.GetCommercialsCSV(state.selectedClient!),
        {
          dateFrom: getDateOnlyIsoString(state.dates[0]),
          dateTo: getDateOnlyIsoString(state.dates[1]),
          supplierIds: state.selectedSupplierIds,
          searchField: state.searchText,
          consessionaryProductsFilter:
            state.productFilterBarState.selectedConcessionaryStates,
          controlledDrugsFilter:
            state.productFilterBarState.selectedControlledDrugsStates,
          brandedFilter: state.productFilterBarState.selectedGenericsStates,
          legalCategoriesFilter:
            state.productFilterBarState.selectedLegalCategoriesStates,
          tariffCategoriesFilter:
            state.productFilterBarState.selectedTariffCategoriesStates,
          rollOverConcession: state.rollConcessionsOver,
          groupByVmp: state.groupByGeneric,
          preferIndicativePriceForBranded: state.useTradePrice,
          useSupplierNetPrices: state.useSuppliersNetPrices,
        },
        'StockBaseUrl',
        undefined,
        false
      )
      return response
    }
    if (state.selectedClient) {
      setIsDownloading(true)
      dispatch({ type: 'setError', payload: null })
      const response = await getCommercialsExport()
      if (!response?.hasErrors) {
        const blob = await response?.rawResponse?.blob()
        if (blob) {
          window.open(window.URL.createObjectURL(blob), '_blank')
          setExportModalOpen(false)
          setIsDownloading(false)
        }
      } else {
        setExporErrors(GetErrorMessage(response.statusCode))
        setIsDownloading(false)
      }
    }
  }

  const renderSupplierDistributionButtonContent = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: theme.spacing(1),
        }}
      >
        <PieChartIcon
          sx={{ marginLeft: `-${theme.spacing(0.5)}`, marginTop: '-2px' }}
          fontSize="large"
        />
        {translation.SupplierDistribution}
      </Box>
    )
  }

  const renderAverageRebateMargin = () => {
    const margin = calculateAverageRebateMargin(state.filteredItems)
    let text = '-'
    let color = theme.palette.common.black

    if (margin) {
      color =
        margin > 0
          ? theme.palette.secondary.light
          : margin < 0
          ? theme.palette.error.main
          : theme.palette.common.black

      text = `${margin.toFixed(2)}%`
    }

    return (
      <Typography variant="h6" sx={{ fontWeight: 600, color: color }}>
        {text}
      </Typography>
    )
  }

  const handleRowClick = (rowData: CommercialsItem) => {
    setSelectedDetails(rowData as CommercialsItem)
  }

  const handleProductFilterBarStateChange = useCallback(
    (productFilterState: ProductFilterBarState) => {
      dispatch({
        type: 'setProductsFilterBarState',
        payload: productFilterState,
      })
    },
    [dispatch]
  )

  return (
    state.selectedClient && (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          marginTop: theme.spacing(2),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            gap: theme.spacing(1),
          }}
        >
          <FilterBar
            state={state}
            dispatch={dispatch}
            searchPlaceholder={translation.SearchPlaceholder}
            datePickerPresetKey={
              DatePresetsLocalStorageKeys.COMMERCIALS_DATE_RANGE_PRESET_KEY
            }
            searchFieldTestId={'search-text-field'}
            allowNullDates={false}
            searchResetsPaging={false}
            sx={{ flex: 1 }}
          />
          <Button
            data-testid="export-to-csv-button"
            variant="outlined"
            disabled={
              (state.filteredItems?.length ?? 0) === 0 || state.groupByGeneric
            }
            onClick={() => {
              setExportModalOpen(true)
            }}
          >
            {isDownloading ? (
              <CircularProgress size={20} />
            ) : (
              <FileDownloadIcon />
            )}
          </Button>
        </Box>
        <ProductFilterBar
          state={state.productFilterBarState}
          onProductFilterBarStateChange={handleProductFilterBarStateChange}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            gap: theme.spacing(1),
            marginBottom: theme.spacing(1),
          }}
        >
          <SwitchWithInfo
            checked={state.rollConcessionsOver}
            onChange={() => {
              dispatch({
                type: 'setRollConcessionsOver',
                payload: !state.rollConcessionsOver,
              })
            }}
            testId="product-popup-roll-over-concession-toggle"
            label={translation.PreviousConcessionPriceToggle}
            infoTooltipText={translation.InfoRollConcessionsOverToggle}
          />
          <SwitchWithInfo
            checked={state.useTradePrice}
            onChange={() => {
              dispatch({
                type: 'setUseTradePrice',
                payload: !state.useTradePrice,
              })
            }}
            testId="use-trade-price-toggle"
            label={translation.UseTradePriceToggle}
            infoTooltipText={translation.InfoUseTradePriceToggle}
          />
          <SwitchWithInfo
            checked={state.groupByGeneric}
            onChange={() => {
              dispatch({
                type: 'setGroupByGeneric',
                payload: !state.groupByGeneric,
              })
            }}
            testId="group-by-generic-toggle"
            label={translation.GroupByGenericToggle}
            infoTooltipText={translation.InfoGroupingToggle}
          />

          {/* TODO: feature flags */}
          <SwitchWithInfo
            checked={state.useSuppliersNetPrices}
            onChange={() => {
              dispatch({
                type: 'setUseSuppliersNetPrices',
                payload: !state.useSuppliersNetPrices,
              })
            }}
            testId="use-supplier-net-prices-toggle"
            label={translation.UseSupplierNetPricesToggle}
            infoTooltipText={translation.InfoUseSupplierNetPricesToggle}
          />
        </Box>
        {!state.error && state.filteredItems && (
          <>
            <VirtuosoMuiTable
              noRowsMessage={translation.NoRecords}
              columns={getColumnDefinitions}
              rows={state.filteredItems}
              onEndReached={virtuosoTableHelpers.handleInfiniteLoading}
              sorting={state.sorting}
              onSortingChanged={virtuosoTableHelpers.handleSortingChanged}
              initialTableState={virtuosoTableState.virtuosoState}
              onTableStateChanged={virtuosoTableHelpers.handleTableStateChanged}
              calculateRowSx={(rowData) => (rowData.isVmp ? VmpRowCss : {})}
              onRowClick={(rowData) => {
                handleRowClick(rowData)
              }}
            ></VirtuosoMuiTable>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingTop: theme.spacing(1),
                gap: theme.spacing(1),
                height: '56px',
              }}
            >
              <Box>
                <ButtonPopOver
                  buttonContent={renderSupplierDistributionButtonContent()}
                  placement="top-start"
                >
                  <SuppliersChart
                    supplierDistribution={
                      state.commercialsResponse?.supplierDistribution ?? null
                    }
                    allSuppliers={state.availableSuppliers}
                    headerLabel={translation.AllProducts}
                  />
                </ButtonPopOver>
              </Box>
              {!state.groupByGeneric && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: theme.spacing(1),
                  }}
                  data-testid="total-spend-container"
                >
                  <Typography variant="overline" sx={{ fontSize: '0.75rem' }}>
                    {translation.Total}
                  </Typography>
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    {toCurrencyString(
                      sum(
                        state.filteredItems.map((i) => i?.totalSpent ?? 0) ?? [
                          0,
                        ]
                      )
                    )}
                  </Typography>
                  <Typography variant="overline" sx={{ fontSize: '0.75rem' }}>
                    {translation.TotalVolumeForProducts}
                  </Typography>
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    {sum(
                      state.filteredItems.map((i) => i?.totalQuantity ?? 0) ?? 0
                    )}
                  </Typography>
                  <Typography variant="overline" sx={{ fontSize: '0.75rem' }}>
                    {translation.AverageRebateMargin}
                  </Typography>
                  {renderAverageRebateMargin()}
                </Box>
              )}
            </Box>
          </>
        )}
        {state.error && (
          <Alert variant="filled" severity="error">
            {state.error}
          </Alert>
        )}
        {Boolean(selectedDetails) && (
          <CommercialsProductDetailsTabs
            commercialsItem={selectedDetails!}
            availableSuppliers={state.availableSuppliers}
            odsCodeToPharmacyMappings={odsToPharmacyNameMappings}
            intialDateFrom={state.dates[0]!}
            initialDateTo={state.dates[1]!}
            initialSupplierIds={state.selectedSupplierIds}
            initialRollOverConcessions={state.rollConcessionsOver}
            enableSupplierNetPrices={state.useSuppliersNetPrices}
            onClosedCallback={(needsUpdate) => {
              setSelectedDetails(null)
              if (needsUpdate) {
                dispatch({ type: 'setForceRefresh', payload: false })
              }
            }}
          />
        )}
        {exportModalOpen && (
          <ConfirmDialog
            title={translation.ExportToCSVModal.Title}
            onCancel={() => {
              setExportModalOpen(false)
              setExporErrors(null)
            }}
            onOkWithLoading={() => handleConfirmFileExport()}
            isLoading={state.error ? false : isDownloading}
            canCancel={!isDownloading}
            cancelText={translation.ExportToCSVModal.CancelText}
            okText={translation.ExportToCSVModal.ConfirmText}
            text={translation.ExportToCSVModal.Text(
              getDateOnlyShortMonthString(state.dates[0]),
              getDateOnlyShortMonthString(state.dates[1]),
              state.selectedSupplierIds.length ===
                Object.keys(state.availableSuppliers).length
                ? translation.ExportToCSVModal.AllSuppliersText
                : state.selectedSupplierIds.length === 1
                ? state.availableSuppliers[state.selectedSupplierIds[0]]
                : translation.ExportToCSVModal.MultipleSuppliersText,
              state.filteredItems!.length
            )}
          >
            {exportErrors && (
              <Alert variant="filled" severity="error">
                {exportErrors}
              </Alert>
            )}
          </ConfirmDialog>
        )}
      </Box>
    )
  )
}

export default TabCommercials
